import React from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import webShare, { WebShareInterface } from "react-web-share-api";

const Button = styled.button`
  border: 1px solid #999;
  color: #333;
  padding: 8px 12px;
  width: 50%;
  text-align: center;
  text-decoration: none;
`;

interface Props {
  onClickFallback: () => void;
  fallbackUrl: string;
}

const ShareButton = ({
  share,
  isSupported,
  onClickFallback,
  fallbackUrl,
}: WebShareInterface & Props) =>
  isSupported ? (
    <Button onClick={share}>Share Poll</Button>
  ) : (
    <CopyToClipboard text={fallbackUrl} onCopy={() => onClickFallback()}>
      <Button>Copy Poll URL</Button>
    </CopyToClipboard>
  );

export default webShare<Props>()(ShareButton);
