import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Layout } from "../layouts/Layout";
import { AuthContext } from "../components/AuthContext";
import { SignIn } from "../components/SignIn";
import QuizGallery from "../components/quiz-browser/QuizGallery";
import Button from "../components/Button";

const MyQuizzes = styled.section`
  display: grid;
  padding: 25px 50px;
  a {
    text-decoration: none;
    color: #222;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    justify-self: start;
  }
`;

export const QuizBrowserPage = ({ location }: any) => {
  const { auth, getUserQuizzes } = useContext(AuthContext);
  const [userQuizData, setUserQuizData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const quizData = await getUserQuizzes();
      setUserQuizData(quizData);
    };
    if (auth.isUserSignedIn) {
      getData();
    }
  }, [auth]);
  return (
    <Layout>
      <MyQuizzes>
        <h2>Browse My Polls</h2>
        {!auth.isUserSignedIn ? (
          <SignIn />
        ) : userQuizData ? (
          <QuizGallery location={location} data={userQuizData} />
        ) : (
          <p>No Polls</p>
        )}
        <Link to="/builder">
          <Button onClick={() => false} type="secondary">
            Build New Poll
          </Button>
        </Link>
      </MyQuizzes>
    </Layout>
  );
};

export default QuizBrowserPage;
